import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import Layout from "../components/Layout";
import Seo from "../components/Seo";

import { getLangTerm } from "../lang";
import { formatPrice } from "../components/PurchaseButtons";
import { parseStripeData, StripeOfferings } from "../utils/stripe";
import RouteCards from "../components/RouteCards";
import PurchaseButton from "../components/PurchaseButton";
import PoweredByStripe from "../images/svg/powered_by_stripe.svg";
import ExternalLink from "../images/svg/external_link.svg";

const HomePage = ({pageContext}) => {
    const { lang, langData, routeSlugs } = pageContext;

    const getWelcomeImages = () => {
        let images = [];
        let images2 = [];

        routeSlugs.forEach((route, i) => {
            if (route.lang) {
                return;
            }
            const routeLangData = langData[`${lang}-${route.slug}`];
            // debugger;
            if (["gastown", "stanley-park-seawall", "stanley-park-inner"].indexOf(route.slug) > -1) {
                images.push(<div key={i} className={"route-image"}>
                    <img src={route.images[0].appsmall} alt={routeLangData?.title} />
                </div>)
                images2.push(<div key={i} className={"route-image"}>
                    <img src={route.images[1].appsmall} alt={routeLangData?.title} />
                </div>)
            }
        })

        return [...images, ...images2];
    }

    return <Layout
        pageContext={pageContext}
        extraClass={"home-container"}
    >
        <Seo title={getLangTerm("home", lang)} />

        <div className="content-row home-1">
            <div className="seventy">
                <h1>{ getLangTerm("explore-vancouver", lang) }</h1>
                <p className="sub-title">
                    <span className="browser">{ getLangTerm("browser-based-web-app", lang) }</span>
                    <span className="account">{ getLangTerm("no-app-no-accounts", lang) }</span>
                </p>
                <a href="https://map.vanwalks.ca" target="_blank" rel="noreferrer" className="try-now-link">
                    { getLangTerm("try-now", lang) }
                    <ExternalLink height="24" width="24" />
                </a>
                <span className="offline-mode">{ getLangTerm("offline-mode", lang) }</span>
                <span className="premium-content">{ getLangTerm("unlock-premium-content-with-email", lang) }</span>
                <span className="languages">{ getLangTerm("languages-supported", lang) }</span>
            </div>
            <div className="thirty screenshot-home">
                <StaticImage
                    className="screenshot"
                    src="../images/home_screenshot_2.png"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="VanWalks Screenshot"
                    objectFit="contain"
                    placeholder={"none"}
                />
            </div>
        </div>
        <div className="content-row home-2">
            <div className="half screenshot-home-2">
                <StaticImage
                    className="screenshot"
                    src="../images/home_screenshot_1.png"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="VanWalks Screenshot"
                    objectFit="contain"
                    placeholder={"none"}
                />
            </div>
            <div className="half">
                <h1>{ getLangTerm("home-2-header", lang) }</h1>
                <p>{ getLangTerm("optimize", lang) }</p>
                <p>{ getLangTerm("our-host", lang) }</p>
                <p>{ getLangTerm("context", lang) }</p>
            </div>
        </div>
        <div className="home-routes">
            <div className="vancouver-bundle">
                <h1>{ getLangTerm("welcome-to-vancouver-bundle", lang) }</h1>
                <div className="images">

                    <Carousel
                        dynamicHeight={false}
                        showStatus={false}
                        showThumbs={false}
                    >
                        { getWelcomeImages(routeSlugs) }
                    </Carousel>
                </div>
                <div className="description">
                    <p dangerouslySetInnerHTML={ {__html: getLangTerm("vancouver-bundle-description", lang)}} />
                    <StripeOfferings renderFunction={({data}) => {

                        const { targetOffering, fullPrice, savings } = parseStripeData({productKey : "vancouver_bundle", routeSlugs, stripeData: data});
                        const { metadata } = targetOffering;
                        const { fr_title } = metadata;

                        // console.log({ targetOffering, fullPrice, savings })
                        const displayName = lang == "fr" && fr_title ? fr_title : targetOffering.name;

                        
                        return <div className="pricing-container">
                            <span className="bundle-price">{ getLangTerm("bundle-price", lang)}: {formatPrice(targetOffering.unit_amount, targetOffering.currency)}</span>
                            <span className="savings-row">
                                <span className="full-price">{ getLangTerm("regular-price", lang) }: {formatPrice(fullPrice, targetOffering.currency)}</span>
                                <span className="save-home">{ getLangTerm("save", lang) } { savings }%</span>
                            </span>
                            <div className="button-row">
                                <PurchaseButton priceID={targetOffering.id} name={displayName}>
                                    { getLangTerm("buy-now", lang) }: {formatPrice(targetOffering.unit_amount, targetOffering.currency)}
                                </PurchaseButton>
                                <PoweredByStripe className="stripe" height="30" width="138"  />
                            </div>
                        </div>;
                    }} />
                    <p className="sub-heading">{ getLangTerm("bundle-includes", lang) }</p>
                </div>
            </div>
            <h1>{ getLangTerm("available-routes", lang) }</h1>
            <RouteCards routeSlugs={routeSlugs} langData={langData} lang={lang} />
        </div>
        <div className="vanwalks-media">
            <div className="media-container">
                <div className="news-articles">
                    <h1>{ getLangTerm("vanwalks-in-the-media", lang) }</h1>
                    <div className="articles">
                        <a
                            href="https://www.vancouverisawesome.com/vancouver-news/this-new-app-guides-users-on-history-walks-around-vancouver-3314966"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className="article">
                                <StaticImage
                                    className="via-photo"
                                    src="../images/VIA_Photo.png"
                                    quality={95}
                                    formats={["auto", "webp", "avif"]}
                                    alt="Vancouver is Awesome"
                                    objectFit="contain"
                                    placeholder={"none"}
                                />
                                <h2>This new app guides users on history walks around Vancouver</h2>
                                <span className="article-date">Feb 2, 2021</span>
                                <p>
                                        Historic sites don&apos;t exist on a planned path, so Chris King built an app to help guide visitors and locals along some of the best routes in the city.
                                </p>
                                <div className="read-more">
                                    { getLangTerm("view-on-vancouverisawesome-com", lang) }
                                </div>
                            </div>
                        </a>
                        <a
                            href="https://www.surreynowleader.com/entertainment/new-starwalk-tour-maps-b-c-s-most-celebrated-entertainers-for-free-on-vanwalks-app-3002338"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className="article">
                                <h2>{ getLangTerm("surrey-now-leader-header", lang) }</h2>
                                <p>
                                    { getLangTerm("surrey-now-leader-content-1", lang) }
                                </p>
                                <p>
                                    { getLangTerm("surrey-now-leader-content-2", lang) }
                                </p>
                                <div className="read-more">
                                    { getLangTerm("view-on-surrey-now-leader-com", lang) }
                                </div>
                            </div>

                        </a>
                        <a
                            href="https://miss604.com/2022/05/10-tours-in-downtown-vancouver.html"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className="article">
                                <h2>{ getLangTerm("miss604-header", lang) }</h2>
                                <p>
                                    { getLangTerm("miss604-content", lang) }
                                </p>
                                <div className="read-more">
                                    { getLangTerm("view-on-miss604-com", lang) }
                                </div>
                            </div>
                        </a>
                        <div className="article">
                            <h2>{ getLangTerm("radio-header", lang) }</h2>
                            <p>
                                { getLangTerm("radio-content", lang) }
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="news-players">
                    <h2>980 CKNW - News Radio</h2>
                    <iframe
                        src="https://omny.fm/shows/the-jill-bennett-show/new-vanwalks-app-offers-a-free-educational-experie/embed?style=cover"
                        frameBorder="0" width="100%" height="180">
                    </iframe>
                    <h2>980 CKNW - The App Show</h2>
                    <iframe src="https://omny.fm/shows/cknw/appshow-july-25-mental-health-and-addiction-apps-w/embed" width="100%" height="180px" frameBorder="0"></iframe>
                           
                </div> */}
            </div>
        </div>

        {/* <StaticImage
            src="../images/VanWalksLogo.png"
            width={300}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="VanWalks Logo"
            style={{ marginBottom: `1.45rem` }}
        />
        <h2>Routes</h2>
        <ol>
            {routeSlugs.map((slug, i) => {
                const slugLang = routeLang[`${lang}-${slug.slug}`]
                return <li key={i}>
                    <a href={`${langPath}/routes/${slug.slug}`}>{slugLang.title}</a>
                </li>
            })}
        </ol>
        <h2>Articles</h2>
        <ol>
            {articles.map((article, i) => {
                return (
                    <li key={i}>
                        <a href={`${langPath}/articles/${article.slug}`}>
                            {article.lang[lang].content.title}
                        </a>
                    </li>
                );
            })}
        </ol>
        <h2>Places</h2>
        <ol>
            {places.map((place, i) => {
                const placeLang = place.lang[lang] || {};
                return (
                    <li key={i}>
                        <a href={`${langPath}/places/${place.slug}`}>
                            {placeLang.title}
                        </a>
                    </li>
                );
            })}
        </ol> */}
    </Layout>
}

export default HomePage
